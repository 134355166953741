import React, { useRef, useState, useEffect, useMemo } from "react";
import { Form, FormControl, Modal } from "react-bootstrap";
import * as yup from "yup";
import { toast } from "react-toastify";
import Select from "react-select";
import closeIcon from "../../../../assets/images/close-white.svg";
import uploadIcon from "../../../../assets/images/upload.svg";
import mediaAPI from "../../../../apis/api/media";
import galleryApi from "../../../../apis/api/gallery";
import { ContentSectionOptions, ContentSection } from "..";
import { GALLERY_TYPE } from "../../../../constants/options";
import { MEDIA_TYPE_ENUM } from "../../../../constants/master-data";
import { useDispatch, useSelector } from "react-redux";
import { reqGetListUnits } from "../../../../reduxs/cms/action";

function addNextNumber(arr) {
  let newArr = [...arr];
  if (arr.length === 0) {
      newArr.push(1);
  } else {
    const maxNumber = Math.max(...newArr);
    newArr.push(maxNumber + 1);
  }
  return newArr;
}

const validationFilmSchema = yup.object().shape({
  file: yup.mixed().required("File is a required field"),
  thumbnail: yup.mixed().required("File is a required field"),
  contentTitle: yup
    .string()
    .trim()
    .required("Content title is a required field"),
  contentSection: yup
    .string()
    .trim()
    .required("Content section is a required field"),
});

const validationSchema = yup.object().shape({
  file: yup.mixed().required("File is a required field"),
  contentTitle: yup
    .string()
    .trim()
    .required("Content title is a required field"),
  contentSection: yup
    .string()
    .trim()
    .required("Content section is a required field"),
});

const floorPlanValidationSchema = yup.object().shape({
  file: yup.mixed().required("File is a required field"),
  contentTitle: yup
    .string()
    .trim()
    .required("Content title is a required field"),
  contentSection: yup
    .string()
    .trim()
    .required("Content section is a required field"),
  unitId: yup
    .string()
    .trim()
    .required("Associated Residence is a required field"),
});

const AddContentModal = ({
  show,
  contentType,
  setShow,
  mediaType = MEDIA_TYPE_ENUM.IMAGE,
  onCreateSuccess,
  uniqueOrder
}) => {
  const dispatch = useDispatch();
  const units = useSelector((state) => state.cms.units);

  const [file, setFile] = useState();
  const [order, setOrder] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [contentTitle, setContentTitle] = useState("");
  const [contentSection, setContentSection] = useState(
    ContentSectionOptions.find((v) => v.label === contentType)?.label
  );
  const [isShowReplaceContentModal, setIsShowReplaceContentModal] =
    useState(false);
  const [enableBtnSubmit, setEnableBtnSubmit] = useState(true);
  const [thumbnail, setThumbnail] = useState();
  const [replaceContentType, setReplaceContentType] = useState("");
  const [errors, setErrors] = useState({});
  const fileRef = useRef();
  const thumbnailRef = useRef();
  const [unitId, setUnitId] = useState("");

  const isFloorPlansSection = contentSection
    ? contentSection === ContentSection.Floorplans
    : contentType === ContentSection.Floorplans;
  
  const newOrderList = useMemo(() => addNextNumber(uniqueOrder), [uniqueOrder]);

  const ordersOptions = useMemo(() => {
    return newOrderList?.map(order => ({
        label: order,
        value: order
    }));
  }, [newOrderList]);

  useEffect(() => {
    if (file) {
      setContentTitle(file.name);
    }
  }, [file]);

  useEffect(() => {
    dispatch(reqGetListUnits());
  }, []);

  const handleAddNewContent = () => {
    // if (!enableBtnSubmit) {
    //   toast.info("Please wait, media file uploading!");
    //   return;
    // }
    setIsLoading(true);
    switch (contentSection || contentType) {
      case ContentSection.Exteriors:
        handleAddContentExteriors();
        return;
      case ContentSection.Interiors:
        handleAddContentInteriors();
        return;
      case ContentSection.Amenities:
        handleAddContentAmenities();
        return;
      case ContentSection.Floorplans:
        handleAddNewContentFloorplans();
        return;
      case ContentSection.Films:
        handleAddNewContentFilm();
        return;
      case ContentSection.Infos:
        handleAddContentInfos();
        return;
    }
  };

  const handleAddNewContentFilm = async () => {
    try {
      const data = {
        order,
        contentTitle,
        contentSection,
        file,
        thumbnail,
      };
      setErrors({});
      const result = await validationFilmSchema.validate(data, { abortEarly: false });

      setEnableBtnSubmit(false);
      const formData = new FormData();
      formData.append("type", "video");
      formData.append("name", result?.contentTitle);
      formData.append("path", "media/media_videos/films");
      formData.append("file", file);

      const formDataThumbnail = new FormData();
      formDataThumbnail.append("type", "image");
      formDataThumbnail.append("name", result?.contentTitle);
      formDataThumbnail.append("path", "media/media_videos/thumbnails");
      formDataThumbnail.append("file", thumbnail);

      toast.info("Please wait, media file uploading!");
      const uploaded = await mediaAPI.uploadMedia(formData);
      const uploadedThumbnail = await mediaAPI.uploadMedia(formDataThumbnail);

      if (uploaded?.data && uploadedThumbnail?.data) {
        await galleryApi.createGallery({
          type: GALLERY_TYPE.FILMS,
          media: [uploaded?.data?.id, uploadedThumbnail?.data?.id],
          category: "films",
        });

        const formData = new FormData();
        formData.append("type", "video");
        formData.append("order", result?.order ?? newOrderList[newOrderList.length - 1]);
        await mediaAPI.updateMedia(uploaded?.data?.id, formData);
        
        toast.success("Add new content successfully!");
        handleClose && handleClose();
        onCreateSuccess && onCreateSuccess();
      }
    } catch (err) {
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      }
      toast.error("Something went wrong");
      setEnableBtnSubmit(true);
    } finally {
      setIsLoading(false)
    }
  };

  const handleAddNewContentFloorplans = async () => {
    try {
      const data = {
        contentTitle,
        contentSection,
        file,
        unitId,
      };
      setErrors({});
      const result = await floorPlanValidationSchema.validate(data, {
        abortEarly: false,
      });

      setEnableBtnSubmit(false);
      const formData = new FormData();
      formData.append("type", "image");
      formData.append("name", result.contentTitle);

      formData.append("path", "media/floorplans");
      formData.append("file", file);

      toast.info("Please wait, media file uploading!");

      const uploaded = await mediaAPI.uploadMedia(formData);

      if (uploaded?.data) {
        await galleryApi.createGallery({
          type: GALLERY_TYPE.FLOOR_PLANS,
          media: [uploaded?.data?.id],
          unitId,
        });
        toast.success("Add new content successfully!");
        handleClose && handleClose();
        onCreateSuccess && onCreateSuccess();
      }
    } catch (err) {
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      }
      setEnableBtnSubmit(true);
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false)
    }
  };

  const handleAddContentInteriors = async () => {
    try {
      const data = {
        order,
        contentTitle,
        contentSection,
        file,
      };
      setErrors({});
      const result = await validationSchema.validate(data, {
        abortEarly: false,
      });

      const formData = new FormData();
      formData.append("type", "image");
      formData.append("name", result?.contentTitle);
      formData.append("path", "media/media_images/image_gallery");
      formData.append("file", file);

      toast.info("Please wait, media file uploading!");
      const uploaded = await mediaAPI.uploadMedia(formData);

      if (uploaded?.data) {
        await galleryApi.createGallery({
          type: "media_images",
          media: [uploaded?.data?.id],
          category: "interiors",
          order: result?.order,
        });

        const formData = new FormData();
        formData.append("type", "image");
        formData.append("order", result?.order ?? newOrderList[newOrderList.length - 1]);
        await mediaAPI.updateMedia(uploaded?.data?.id, formData);

        toast.success("Add new content successfully!");
        handleClose && handleClose();
        onCreateSuccess && onCreateSuccess();
      }
    } catch (err) {
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      }
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false)
    }
  };

  const handleAddContentAmenities = async () => {
    try {
      const data = {
        order,
        contentTitle,
        contentSection,
        file,
      };
      setErrors({});
      const result = await validationSchema.validate(data, {
        abortEarly: false,
      });

      const formData = new FormData();
      formData.append("type", "image");
      formData.append("name", result?.contentTitle);
      formData.append("path", "media/media_images/image_gallery");
      formData.append("file", file);

      toast.info("Please wait, media file uploading!");

      const uploaded = await mediaAPI.uploadMedia(formData);

      if (uploaded?.data) {
        await galleryApi.createGallery({
          type: "media_images",
          media: [uploaded?.data?.id],
          category: "amenities",
          order: result?.order,
        });

        const formData = new FormData();
        formData.append("type", "image");
        formData.append("order", result?.order ?? newOrderList[newOrderList.length - 1]);
        await mediaAPI.updateMedia(uploaded?.data?.id, formData);

        toast.success("Add new content successfully!");
        handleClose && handleClose();
        onCreateSuccess && onCreateSuccess();
      }
    } catch (err) {
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      }
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false)
    }
  };

  const handleAddContentExteriors = async () => {
    try {
      const data = {
        order,
        contentTitle,
        contentSection,
        file,
      };
      setErrors({});
      const result = await validationSchema.validate(data, {
        abortEarly: false,
      });

      const formData = new FormData();
      formData.append("type", "image");
      formData.append("name", result?.contentTitle);
      formData.append("path", "media/media_images/image_gallery");
      formData.append("file", file);
      toast.info("Please wait, media file uploading!");

      const uploaded = await mediaAPI.uploadMedia(formData);

      if (uploaded?.data) {
        await galleryApi.createGallery({
          type: "media_images",
          media: [uploaded?.data?.id],
          category: "exteriors",
          order: result?.order,
        });

        const formData = new FormData();
        formData.append("type", "image");
        formData.append("order", result?.order ?? newOrderList[newOrderList.length - 1]);
        await mediaAPI.updateMedia(uploaded?.data?.id, formData);

        toast.success("Add new content successfully!");
        handleClose && handleClose();
        onCreateSuccess && onCreateSuccess();
      }
    } catch (err) {
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      }
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false)
    }
  };

  const handleAddContentInfos = async () => {
    try {
      const data = {
        order,
        contentTitle,
        contentSection,
        file,
      };
      setErrors({});
      const result = await validationSchema.validate(data, {
        abortEarly: false,
      });

      const formData = new FormData();
      formData.append("type", "image");
      formData.append("name", result?.contentTitle);
      formData.append("path", "media/media_images/image_gallery");
      formData.append("file", file);
      toast.info("Please wait, media file uploading!");

      const uploaded = await mediaAPI.uploadMedia(formData);

      if (uploaded?.data) {
        await galleryApi.createGallery({
          type: "media_images",
          media: [uploaded?.data?.id],
          category: "infos",
          order: result?.order,
        });

        const formData = new FormData();
        formData.append("type", "image");
        formData.append("order", result?.order ?? newOrderList[newOrderList.length - 1]);
        await mediaAPI.updateMedia(uploaded?.data?.id, formData);

        toast.success("Add new content successfully!");
        handleClose && handleClose();
        onCreateSuccess && onCreateSuccess();
      }
    } catch (err) {
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      }
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false)
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const onOpenFileInput = () => {
    if (!file || isShowReplaceContentModal) {
      fileRef.current.click();
    } else {
      handleOpenReplaceContentModal(mediaType);
    }
  };

  const onOpenThumbnailInput = () => {
    if (!thumbnail || isShowReplaceContentModal) {
      thumbnailRef.current.click();
    } else {
      handleOpenReplaceContentModal("thumbnail");
    }
  };

  const renderImagePreview = () => {
    if (file) {
      return URL.createObjectURL(file);
    }

    return "/images/image.png";
  };

  const renderThumbnailPreview = () => {
    if (thumbnail) {
      return URL.createObjectURL(thumbnail);
    }

    return "/images/image.png";
  };

  const handleBrowserFile = (e) => {
    const isImage = e.target.files[0].type.startsWith("image");
    // if (!isImage) return;

    setFile(e.target.files[0]);
    handleCloseReplaceContentModal();
  };

  const handleBrowseThumbnail = (e) => {
    setThumbnail(e.target.files[0]);
    handleCloseReplaceContentModal();
  };

  const handleOpenReplaceContentModal = (type) => {
    setIsShowReplaceContentModal(true);
    setReplaceContentType(type);
  };

  const handleCloseReplaceContentModal = () => {
    setIsShowReplaceContentModal(false);
    setReplaceContentType("");
  };

  if (isShowReplaceContentModal) {
    return (
      <Modal
        className="wrap-replace-content-modal"
        show={isShowReplaceContentModal}
        onHide={handleCloseReplaceContentModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>REPLACE CONTENT</Modal.Title>
        </Modal.Header>
        <Modal.Body className="wrap-modal-body">
          <div className="close-btn">
            <img
              src={closeIcon}
              alt="close-icon"
              onClick={handleCloseReplaceContentModal}
            />
          </div>
          <div
            className="browser-file"
            onClick={
              replaceContentType === "thumbnail"
                ? onOpenThumbnailInput
                : onOpenFileInput
            }
          >
            BROWSE FILES
            <input
              ref={replaceContentType === "thumbnail" ? thumbnailRef : fileRef}
              accept={`${replaceContentType === "video" ? "video" : "image"}/*`}
              id="file"
              className="hidden"
              type="file"
              onChange={(e) => (replaceContentType === "thumbnail" ? handleBrowseThumbnail(e) : handleBrowserFile(e))}
            />
          </div>
          {mediaType !== MEDIA_TYPE_ENUM.VIDEO && (
            <div className="import" onClick={() => {}}>
              <span className="import-title">Or import from a URL</span>
              <div className="import-form">
                <input type="text" placeholder="Add a URL" />
                <span className="import-button">IMPORT</span>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Modal
      className="wrap-create-content-modal"
      show={show}
      onHide={handleClose}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>ADD NEW CONTENT</Modal.Title>
      </Modal.Header>
      <Modal.Body className="wrap-modal-body">
        <div className="close-btn">
          <img src={closeIcon} alt="close-icon" onClick={handleClose} />
        </div>
        <div className="content mt-20">
          <div className="title">CONTENT</div>
          {file ? (
            <>
              {mediaType === MEDIA_TYPE_ENUM.IMAGE && (
                <img
                  className="img-fluid cursor-pointer"
                  src={renderImagePreview()}
                  alt=""
                />
              )}
              {mediaType === MEDIA_TYPE_ENUM.VIDEO && (
                <video className="img-fluid cursor-pointer">
                  <source src={renderImagePreview()} type="video/mp4" />
                </video>
              )}
              <input
                ref={fileRef}
                accept={`${mediaType}/*`}
                id="file"
                className="hidden"
                type="file"
                onChange={(e) => handleBrowserFile(e)}
                disabled={isLoading}
              />
              <button disabled={isLoading} onClick={onOpenFileInput} className="upload-btn">
                <img
                  className="upload-icon"
                  src={uploadIcon}
                  alt="upload-icon"
                />
              </button>
            </>
          ) : (
            <>
              <button disabled={isLoading} className="browser-file" onClick={onOpenFileInput}>
                BROWSE FILES
                <input
                  ref={fileRef}
                  accept={`${mediaType}/*`}
                  id="file"
                  className="hidden"
                  type="file"
                  onChange={(e) => handleBrowserFile(e)}
                  disabled={isLoading}
                />
              </button>
            </>
          )}
          <span className="error">{errors?.file}</span>
        </div>

        {mediaType === MEDIA_TYPE_ENUM.VIDEO && (
          <div className="content">
            <div className="title">THUMBNAIL</div>
            <div div className="upload-input">
              {thumbnail ? (
                <>
                  <img
                    style={{ width: '100%' }}
                    className="img-fluid cursor-pointer"
                    src={renderThumbnailPreview()}
                    alt=""
                  />
                  <input
                    ref={thumbnailRef}
                    accept={`image/*`}
                    id="file"
                    className="hidden"
                    type="file"
                    onChange={(e) => handleBrowseThumbnail(e)}
                  />
                  <button
                    disabled={isLoading}
                    onClick={onOpenThumbnailInput}
                    className={`upload-btn ${file ? "has-file" : ""}`}
                  >
                    <img
                      className="upload-icon"
                      src={uploadIcon}
                      alt="upload-icon"
                    />
                  </button>
                </>
              ) : (
                <>
                  <button disabled={isLoading} className="browser-file" onClick={onOpenThumbnailInput}>
                    BROWSE FILES
                    <input
                      ref={thumbnailRef}
                      accept={`image/*`}
                      id="file"
                      className="hidden"
                      type="file"
                      onChange={(e) => handleBrowseThumbnail(e)}
                    />
                  </button>
                </>
              )}
            </div>
            <span className="error">{errors?.thumbnail}</span>
          </div>
        )}

        <div className="info">
          <div className="title">INFORMATION</div>
          <div className="wrap-form-group">
            <Form.Group>
              <Form.Label>Content Title*</Form.Label>
              <FormControl
                id="contentTitle"
                name="contentTitle"
                type="input"
                value={contentTitle}
                placeholder="Content Title"
                onChange={(e) => setContentTitle(e.target.value)}
                disabled={isLoading}
              />
              <span className="error">{errors?.contentTitle}</span>
            </Form.Group>
          </div>
          <div className="wrap-form-group">
            <Form.Group>
              <Form.Label>Content Section*</Form.Label>
              <Select
                className="content-section"
                classNamePrefix="select"
                value={contentSection && {
                  value: contentSection,
                  label: contentSection,
                }}
                isDisabled={true}
                options={ContentSectionOptions}
                name="contentSection"
                isSearchable={false}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                onChange={(item) => setContentSection(item?.value)}
                placeholder="Content Section"
              />
              <span className="error">{errors?.contentSection}</span>
            </Form.Group>
          </div>
          {!isFloorPlansSection && (
            <div className="wrap-form-group">
              <Form.Group>
                <Form.Label>Content Order</Form.Label>
                <Select
                  className="content-section"
                  classNamePrefix="select"
                  id="order"
                  name="order"
                  isSearchable={false}
                  menuPortalTarget={document.body}
                  styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  value={ordersOptions?.find(option => option.value === Number(order))}
                  options={ordersOptions}
                  onChange={(e) => setOrder(e.value)}
                  placeholder="Content Order"
                  isDisabled={isLoading}
                />
                <span className="error">{errors?.order}</span>
              </Form.Group>
            </div>
          )}
          {isFloorPlansSection && (
            <div className="wrap-form-group">
              <Form.Group>
                <Form.Label>associated residence</Form.Label>
                <Select
                  className="content-section"
                  classNamePrefix="select"
                  value={{
                    value: unitId || "",
                    label: `Residence ${
                      units.find((unit) => unit.id === unitId)?.name || ""
                    }`,
                  }}
                  options={units.map((unit) => ({
                    label: `Residence ${unit.name}`,
                    value: unit.id,
                  }))}
                  name="unitId"
                  isSearchable={false}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  onChange={(item) => setUnitId(item?.value)}
                />
                <span className="error">{errors?.unitId}</span>
              </Form.Group>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="submit" onClick={handleAddNewContent} disabled={isLoading}>
          SAVE
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddContentModal;
